//import css
import "./scss/main.scss";

//import components js
import "./components/button/button.js";
import "./components/clouds/clouds.js";
import "./components/dialog/dialog.js";
import "./components/global-overlay/global-overlay.js";
import "./components/icon/icon.js";
import "./components/left-side/left-side.js";
import "./components/preloader/preloader.js";
import "./components/right-side/right-side.js";

